import { template as template_ef026a23ab3846b68426d57636fe052c } from "@ember/template-compiler";
const FKLabel = template_ef026a23ab3846b68426d57636fe052c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
