import { template as template_6f619503eb4047e1966b33eabed7e990 } from "@ember/template-compiler";
const WelcomeHeader = template_6f619503eb4047e1966b33eabed7e990(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
